import { PaletteMode, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import AppAppBar from "../components/home/AppAppBar";
import Hero from "../components/home/Hero";
import LogoCollection from "../components/home/LogoCollection";
import Highlights from "../components/home/Highlights";
import Pricing from "../components/home/Pricing";
import Features from "../components/home/Features";
import Testimonials from "../components/home/Testimonials";
import FAQ from "../components/home/FAQ";
import Footer from "../components/home/Footer";
import getLPTheme from "./getLPTheme";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getMobileOperatingSystem,
  scrollToSection,
} from "../../services/general_services";

const defaultTheme = createTheme({});

interface ToggleCustomThemeProps {
  showCustomTheme: Boolean;
  toggleCustomTheme: () => void;
}

function ToggleCustomTheme({
  showCustomTheme,
  toggleCustomTheme,
}: ToggleCustomThemeProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100dvw",
        position: "fixed",
        bottom: 24,
      }}
    >
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={showCustomTheme}
        onChange={toggleCustomTheme}
        aria-label="Platform"
        sx={{
          backgroundColor: "background.default",
          "& .Mui-selected": {
            pointerEvents: "none",
          },
        }}
      >
        <ToggleButton value>
          <AutoAwesomeRoundedIcon sx={{ fontSize: "20px", mr: 1 }} />
          Custom theme
        </ToggleButton>
        <ToggleButton value={false}>Material Design 2</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}

export default function HomeScreen({ type }: any) {
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const section = params.get("section");
  const [mode, setMode] = useState<PaletteMode>("dark");
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const LPtheme = createTheme(getLPTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  useEffect(() => {
    if (section) {
      if (
        section === "features" ||
        section === "testimonials" ||
        section === "highlights" ||
        section === "faq"
      ) {
        console.log("tryna scroll bro");
        scrollToSection(section, () => {});
      }
    }
  }, [section]);

  // LINK TO APP!
  const deepLink = "geo-go://";
  const appTrigger = params.get("app");

  useEffect(() => {
    let fallbackTimeout: any;

    const redirectToDeepLink = () => {
      let location = deepLink;

      // Create an iframe to attempt opening the deep link
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = location;
      document.body.appendChild(iframe);

      // Remove the iframe after attempting
      setTimeout(() => document.body.removeChild(iframe), 1000);
    };

    const redirectToFallback = () => {
      const platform = getMobileOperatingSystem();
      const fallbackLink =
        platform === "Android"
          ? "https://play.google.com/store/apps/details?id=com.GeoGoApp"
          : "https://apps.apple.com/gb/app/geo-go-go-executive-out/id6467105160";

      console.log("Fallback?");
      window.location.href = fallbackLink;
    };

    const tryRedirect = () => {
      try {
        redirectToDeepLink();

        fallbackTimeout = setTimeout(() => {
          redirectToFallback();
        }, 200); // Adjust timeout as needed
      } catch (err) {
        console.log(err);
      }
    };

    const handleBlur = () => {
      // If the window loses focus before the timeout fires, clear the timeout
      clearTimeout(fallbackTimeout);
    };

    if (appTrigger) {
      tryRedirect();

      window.addEventListener("blur", handleBlur);

      // Cleanup function to remove the event listener
      return () => {
        window.removeEventListener("blur", handleBlur);
      };
    }
  }, [appTrigger]);

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero />
      <Box sx={{ bgcolor: "background.default" }}>
        <LogoCollection />
        <Features />
        <Divider />
        <Highlights />
        <Divider />
        <FAQ />
        <Divider />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
