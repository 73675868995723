import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CarClassModel } from "../../../models/CarClassModel";
import theme, { primaryColor } from "../../../assets/style/theme";
import { Location } from "../../../models/LocationModel";
import { Add, Cancel, Close, Delete, Launch, Save } from "@mui/icons-material";
import {
  setCreateNewDriver,
  setDriverList,
  setSelectedEditDriver,
  setSelectedViewDriver,
} from "../../../store/slices/driver.slice";
import ReactImageUploading from "react-images-uploading";
import RegPlateTextField from "../general/TextFieldRegPlate";
import { PaperDropzone } from "../general/PaperDropzone";
import { checkValidEmail } from "../../../services/auth_services";
import { toast } from "react-toastify";
import { auth, db, storage } from "../../../services/firebase_services";
import {
  DriverDocument,
  DriverExpirationModel,
  DriverModel,
  ExpirationModel,
} from "../../../models/DriverModel";
import {
  createStripeAccount,
  getCurrentDateTime,
} from "../../../services/general_services";
import { DriverModalType } from "../../../models/DriverModelType";
import { styles } from "../../../assets/style/styles";
import { isMobile } from "react-device-detect";
import moment from "moment";

import enGB from "date-fns/locale/en-GB";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

interface IProps {
  type: DriverModalType;
}

export function DriverModal({ type }: IProps) {
  const {
    selectedEditDriver,
    selectedViewDriver,
    driverList,
    createNewDriver,
  }: any = useSelector((state: any) => state.DriverSlice);
  const { carClassList, carClassListLoading } = useSelector(
    (state: any) => state.CarClassSlice
  );

  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");

  const [licensingCouncils, setLicensingCouncils] = useState<Location[]>([]);
  const [licensingCouncilsError, setLicensingCouncilsError] = useState("");

  const [carDescriptionShort, setCarDescriptionShort] = useState("");
  const [carDescriptionShortError, setCarDescriptionShortError] = useState("");

  const [carClasses, setCarClasses] = useState<any[]>([]);
  const [carClassesError, setCarClassesError] = useState("");

  const [registrationPlate, setRegistrationPlate] = useState("");
  const [registrationPlateError, setRegistrationPlateError] = useState("");

  const [licenceNumber, setLicenceNumber] = useState("");
  const [licenceNumberError, setLicenceNumberError] = useState("");
  const [badgeNumber, setBadgeNumber] = useState("");
  const [badgeNumberError, setBadgeNumberError] = useState("");

  const [files, setFiles] = useState<any[]>([]);
  const [startingFiles, setStartingFiles] = useState<any[]>([]);

  const [expirations, setExpirations] = useState<ExpirationModel[]>([]);

  const [images, setImages] = useState<any[]>([]);
  const [imagesChanged, setImagedChanged] = useState(false);
  const [imagesError, setImagesError] = useState("");

  const [deletedExpirations, setDeletedExpirations] = useState<
    ExpirationModel[]
  >([]);

  const [deletedFiles, setDeletedFiles] = useState<any[]>([]);

  const maxNumber = 1;

  const dropzoneRef = createRef<any>();

  const onChange = (imageList: any[], addUpdateIndex: any) => {
    setImagedChanged(true);
    // data for submit
    setImages(imageList);
  };

  useEffect(() => {
    if (selectedEditDriver && type === DriverModalType.EDIT) {
      selectedEditDriver.firstName &&
        setFirstName(selectedEditDriver.firstName);
      selectedEditDriver.lastName && setLastName(selectedEditDriver.lastName);
      selectedEditDriver.phoneNumber &&
        setPhoneNumber(selectedEditDriver.phoneNumber);
      selectedEditDriver.emailAddress &&
        setEmailAddress(selectedEditDriver.emailAddress);
      selectedEditDriver.licensingCouncils &&
        setLicensingCouncils(selectedEditDriver.licensingCouncils);
      selectedEditDriver.carDescriptionShort &&
        setCarDescriptionShort(selectedEditDriver.carDescriptionShort);

      selectedEditDriver.carClasses &&
        setCarClasses(
          Array.isArray(selectedEditDriver.carClasses)
            ? selectedEditDriver.carClasses
            : [selectedEditDriver.carClasses]
        );
      selectedEditDriver.profilePictureUrl &&
        setImages([{ data_url: selectedEditDriver.profilePictureUrl }]);
      selectedEditDriver.registrationPlate &&
        setRegistrationPlate(selectedEditDriver.registrationPlate);
      selectedEditDriver.documents &&
        setFiles(
          selectedEditDriver.documents ? [...selectedEditDriver.documents] : []
        );

      selectedEditDriver.documents &&
        setStartingFiles(
          selectedEditDriver.documents ? [...selectedEditDriver.documents] : []
        );

      selectedEditDriver.licenceNumber &&
        setLicenceNumber(selectedEditDriver.licenceNumber);
      selectedEditDriver.badgeNumber &&
        setBadgeNumber(selectedEditDriver.badgeNumber);
      selectedEditDriver.addressLine1 &&
        setAddressLine1(selectedEditDriver.addressLine1);
      selectedEditDriver.addressLine2 &&
        setAddressLine2(selectedEditDriver.addressLine2);
      selectedEditDriver.postCode && setPostCode(selectedEditDriver.postCode);
      selectedEditDriver.city && setCity(selectedEditDriver.city);
      selectedEditDriver.dateOfBirth &&
        setDateOfBirth(new Date(selectedEditDriver.dateOfBirth));
      selectedEditDriver.stripeAccountId &&
        setStripeAcc(selectedEditDriver.stripeAccountId);

      selectedEditDriver.expirations &&
        setExpirations(
          selectedEditDriver.expirations.map((val: ExpirationModel) => {
            return {
              id: val.id,
              expirationDate: val.expirationDate,
              title: val.title,
              uploadDate: val.uploadDate,
              driverId: val.driverId,
              expired: val.expired,
            };
          })
        );
    } else if (!selectedEditDriver && type === DriverModalType.EDIT) {
      clearStates();
    } else if (!createNewDriver && type === DriverModalType.CREATE) {
      clearStates();
    }
  }, [selectedEditDriver]);

  function clearStates() {
    setImages([]);
    setExpirations([]);
    setDeletedExpirations([]);
    setImagesError("");
    setFirstName("");
    setFirstNameError("");
    setLastName("");
    setLastNameError("");
    setPhoneNumber("");
    setPhoneNumberError("");
    setEmailAddress("");
    setEmailAddressError("");
    setCarClasses([]);
    setCarClassesError("");
    setCarDescriptionShort("");
    setCarDescriptionShortError("");
    setRegistrationPlate("");
    setRegistrationPlateError("");
    setLicensingCouncils([]);
    setLicensingCouncilsError("");
    setImagedChanged(false);
    setFiles([]);
    setStripeAcc(undefined);
  }
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(
    name: string,
    personName: readonly string[],
    theme: Theme
  ) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  async function validateDriver() {
    let numErrors = 0;

    //Name validation
    if (firstName === "") {
      setFirstNameError("First name must not be empty");
      numErrors += 1;
    } else if ((firstName && firstName.length > 20) || firstName.length < 2) {
      setFirstNameError("First name length must be between 2-20 characters.");
      numErrors += 1;
    } else if (!/^[A-Za-z]*/.test(firstName)) {
      setFirstNameError("First name must be text only.");
      numErrors += 1;
    } else {
      setFirstNameError("");
    }
    if (lastName === "") {
      setLastNameError("Surname must not be empty");
      numErrors += 1;
    } else if ((lastName && lastName.length > 30) || lastName.length < 2) {
      setLastNameError("Surname length must be between 2-30 characters.");
      numErrors += 1;
    } else if (!/^[A-Za-z]*/.test(lastName)) {
      numErrors += 1;
      setLastNameError("Surname must be text only.");
    } else {
      setLastNameError("");
    }

    // Phone number validation
    if (phoneNumber === "") {
      setPhoneNumberError("Phone number must not be empty.");
      numErrors += 1;
    } else if (phoneNumber.length !== 10) {
      setPhoneNumberError(
        "Phone number must be a valid UK number . E.g +44 7123456789"
      );
      numErrors += 1;
    } else if (!/^[0-9]*$/.test(phoneNumber)) {
      setPhoneNumberError("Phone number must be numbers only.");
      numErrors += 1;
    } else {
      console.log(phoneNumber);
      setPhoneNumberError("");
    }

    // Email
    if (emailAddress === "") {
      setEmailAddressError("Email must not be empty");
      numErrors += 1;
    } else if (emailAddress && emailAddress.length > 40) {
      setEmailAddressError("Email must be less than 40 characters.");
      numErrors += 1;
  } else if (!checkValidEmail(emailAddress)) {
      setEmailAddressError("Email provided is invalid.");
      numErrors += 1;
    } else {
      setEmailAddressError("");
    }

    if (licensingCouncils && licensingCouncils.length === 0) {
      setLicensingCouncilsError(
        "You must have at least one licensing council."
      );
      numErrors += 1;
    } else {
      setLicensingCouncilsError("");
    }

    if (licenceNumber !== undefined && licenceNumber.length !== 16) {
      setLicenceNumberError("Licence number must be 16 characters in length.");
      numErrors += 1;
    } else {
      setLicenceNumberError("");
    }

    if (
      badgeNumber !== undefined &&
      (badgeNumber.length < 3 || badgeNumber.length > 30)
    ) {
      setBadgeNumberError("Badge number must be over 3 characters in length.");
      numErrors += 1;
    } else {
      setBadgeNumberError("");
    }

    if (carDescriptionShort === "") {
      setCarDescriptionShortError("Car description must not be empty");
      numErrors += 1;
    } else if (
      carDescriptionShort &&
      (carDescriptionShort.length > 30 || carDescriptionShort.length < 2)
    ) {
      setCarDescriptionShortError(
        "Car description must be between 2-30 characters."
      );
      numErrors += 1;
    } else if (!/^[A-Za-z]*/.test(carDescriptionShort)) {
      setCarDescriptionShortError("Car description must be text only.");
    } else {
      setCarDescriptionShortError("");
    }

    if (carClasses.length === 0) {
      setCarClassesError("You must have at least one car class.");
      numErrors += 1;
    } else {
      setCarClassesError("");
    }

    if (registrationPlate.length === 0) {
      setRegistrationPlateError("Invalid registration plate provided");
      numErrors += 1;
    } else if (
      !/(^[A-Z]{2}[0-9]{2} [A-Z]{3}$)|(^[A-Z][0-9]{1,3} [A-Z]{3}$)|(^[A-Z]{3} [0-9]{1,3}[A-Z]$)|(^[0-9]{1,4} [A-Z]{1,2}$)|(^[0-9]{1,3} [A-Z]{1,3}$)|(^[A-Z]{1,2} [0-9]{1,4}$)|(^[A-Z]{1,3} [0-9]{1,3}$)*/.test(
        registrationPlate
      )
    ) {
      setRegistrationPlateError("Invalid registration plate provided");
      numErrors += 1;
    } else {
      setRegistrationPlateError("");
    }

    if (images.length === 0) {
      setImagesError(
        "Drivers must have a profile picture for customers to view."
      );
      numErrors += 1;
    } else {
      setImagesError("");
    }

    if (moment(new Date()).diff(moment(dateOfBirth), "years") < 18) {
      numErrors += 1;
      setDateOfBirthError("Drivers must be over 18.");
    } else {
      setDateOfBirthError("");
    }

    if (addressLine1 === "") {
      setAddressLine1Error("Address Line 1 must not be empty");
      numErrors += 1;
    } else if (
      (addressLine1 && addressLine1.length > 100) ||
      addressLine1.length < 2
    ) {
      setAddressLine1Error(
        "Address Line 1 length must be between 2-100 characters."
      );
      numErrors += 1;
    } else if (!/^[A-Za-z]*/.test(addressLine1)) {
      setAddressLine1Error("Address Line 1 must be text only.");
      numErrors += 1;
    } else {
      setAddressLine1Error("");
    }

    if (addressLine2 && addressLine2.length > 100) {
      setAddressLine2Error(
        "Address Line 2 length must be between 2-100 characters."
      );
      numErrors += 1;
    } else if (!/^[A-Za-z]*/.test(addressLine2)) {
      setAddressLine2Error("Address Line 2 must be text only.");
      numErrors += 1;
    } else {
      setAddressLine2Error("");
    }

    if (city && city.length > 50) {
      setCityError("City length must be between 2-50 characters.");
      numErrors += 1;
    } else if (!/^[A-Za-z]*/.test(city)) {
      setCityError("City must be text only.");
      numErrors += 1;
    } else {
      setCityError("");
    }

    if (city && city.length > 50) {
      setCityError("City length must be between 2-50 characters.");
      numErrors += 1;
    } else if (!/^[A-Za-z]*/.test(city)) {
      setCityError("City must be text only.");
      numErrors += 1;
    } else {
      setCityError("");
    }
    if (
      !/^\b(?:[A-Z][A-HJ-Y]?[0-9][0-9A-Z]? ?[0-9][A-Z]{2}|GIR ?0A{2})\b/.test(
        postCode
      )
    ) {
      setPostCodeError("Invalid post code provided.");
      numErrors += 1;
    } else {
      setPostCodeError("");
    }

    let local_expirations = expirations;
    if (expirations.length > 0) {
      for (let i = 0; i < expirations.length; i++) {
        let exp = expirations[i];
        let error = "";

        var today = new Date();

        var myToday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          0,
          0,
          0
        );

        if (exp.title === "") {
          error = "Title must not be empty.";
        } else if (
          moment(exp.expirationDate).isBefore(moment(myToday).add(1, "day"))
        ) {
          error = "Expiration date must be at least one day in the future.";
        } else {
          error = "";
        }

        if (error !== "") {
          local_expirations = local_expirations.map((val) => {
            if (val.id === exp.id) {
              return { ...val, error };
            } else {
              return val;
            }
          });

          numErrors += 1;
        } else {
          local_expirations = local_expirations.map((val) => {
            if (val.id === exp.id) {
              let obj: any = {
                id: val.id,
                expirationDate: val.expirationDate,
                title: val.title,
                uploadDate: val.uploadDate,
                driverId: val.driverId,
                expired: val.expired ? true : false,
                error: error,
              };

              return obj;
            } else {
              return { ...val, expired: val.expired ? true : false };
            }
          });
        }
      }
      setExpirations(local_expirations);
    }
    if (numErrors === 0) {
      toast.info("Starting upload...");

      if (type === DriverModalType.CREATE) {
        uploadNewDriver();
        dispatch(setCreateNewDriver(false));
      } else if (type === DriverModalType.EDIT) {
        alterDriver();
        dispatch(setSelectedEditDriver(undefined));
      }
    } else {
      toast.error("Errors within driver data");
    }
  }

  async function alterDriver() {
    let profilePictureUrl = selectedEditDriver.profilePictureUrl;
    let phoneNum =
      phoneNumber.length === 11 ? phoneNumber.slice(1) : phoneNumber;

    if (!stripeAcc) {
      const response = await fetch("https://api.ipify.org/?format=json");
      const data = await response.json();
      let ip = data.ip;
      createStripeAccount(
        selectedEditDriver.id,
        {
          ...selectedEditDriver,
          firstName,
          lastName,
          phoneNum,
          emailAddress,
        },
        ip,
        dateOfBirth,
        addressLine1,
        addressLine2,
        postCode,
        city,
        Number.parseInt(moment(dateOfBirth).format("D")),
        Number.parseInt(moment(dateOfBirth).format("YYYY")),
        Number.parseInt(moment(dateOfBirth).format("M"))
      );
    }

    try {
      const id = selectedEditDriver.id;
      if (imagesChanged) {
        // Upload image...]
        const response = await fetch(images[0].data_url);
        const selfieBlob = await response.blob();
        // Store the selfie in the firebase store
        profilePictureUrl = await storage
          .ref("/images/" + id)
          .put(selfieBlob)
          .then((snapshot: any) => {
            return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
          });

        toast.success("Image uploaded...");
      }
      let documentList: DriverDocument[] = [];

      for (let i = 0; i < files.length; i++) {
        if (
          !startingFiles.find((val) => val.downloadUrl === files[i].downloadUrl)
        ) {
          // Store the selfie in the firebase store
          let downloadUrl = await storage
            .ref("/documents/" + id + "/" + files[i].name)
            .put(files[i])
            .then((snapshot: any) => {
              return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
            });

          const obj: DriverDocument = {
            name: files[i].name,
            creationDate: getCurrentDateTime(),
            downloadUrl: downloadUrl,
            type: files[i].type,
          };

          documentList.push(obj);
        } else {
          documentList.push(files[i]);
        }
      }

      // Remove drivers from any classes before assigning new ones
      for (let i = 0; i < carClassList.length; i++) {
        db.ref("/car_class_drivers/" + carClassList[i].id + "/" + id).set(
          false
        );
      }

      for (let i = 0; i < carClasses.length; i++) {
        let carClass = carClasses[i];

        db.ref("/car_class_drivers/" + carClass + "/" + id).set(true);
      }

      let exp_list: DriverExpirationModel[] = [];
      // Upload any expirations
      for (let i = 0; i < expirations.length; i++) {
        // Check if uploaded yet...

        let exp = expirations[i];

        if (exp.id.length === 4) {
          let ref = db.ref("/expirations").push({
            id: exp.id,
            driverId: exp.driverId,
            uploadDate: exp.uploadDate,
            expirationDate: exp.expirationDate,
            expired: exp.expired,
            title: exp.title,
          });

          if (ref.key !== null) {
            db.ref(`/expirations/${ref.key}`).set({
              driverId: exp.driverId,
              uploadDate: exp.uploadDate,
              expirationDate: exp.expirationDate,
              id: ref.key,
              expired: exp.expired,
              title: exp.title,
            });

            exp_list.push({ ...exp, id: ref.key, expirationId: ref.key });

            db.ref(`/driver_expirations/${id}/${ref.key}`).set({
              id: ref.key,
              driverId: exp.driverId,
              uploadDate: exp.uploadDate,
              expirationDate: exp.expirationDate,
              expirationId: ref.key,
              expired: exp.expired,
              title: exp.title,
            });
          }
        } else {
          db.ref(`/expirations/${exp.id}`).set({
            driverId: exp.driverId,
            uploadDate: exp.uploadDate,
            expirationDate: exp.expirationDate,
            id: exp.id,
            title: exp.title,
            expired: exp.expired,
          });

          db.ref(`/driver_expirations/${id}/${exp.id}`).set({
            id: exp.id,
            driverId: exp.driverId,
            uploadDate: exp.uploadDate,
            expirationDate: exp.expirationDate,
            expirationId: exp.id,
            title: exp.title,
            expired: exp.expired,
          });

          exp_list.push({
            id: exp.id,
            driverId: exp.driverId,
            uploadDate: exp.uploadDate,
            expirationDate: exp.expirationDate,
            expirationId: exp.id,
            title: exp.title,
            expired: exp.expired,
          });
        }
      }

      for (let i = 0; i < deletedExpirations.length; i++) {
        let exp = deletedExpirations[i];

        if (exp.id && id) {
          db.ref("/expirations/" + exp.id).remove();

          db.ref("/driver_expirations/" + id + "/" + exp.id).remove();
        }
      }

      for (let i = 0; i < deletedFiles.length; i++) {
        let file = deletedFiles[i];

        if (file.id && id) {
          await storage.ref("/documents/" + id + "/" + file.name).delete();
        }
      }

      let driverObj: DriverModel = {
        firstName,
        lastName,
        id: selectedEditDriver.id,
        driver: true,
        emailAddress: emailAddress,
        registrationPlate: registrationPlate,
        documents: documentList,
        profilePictureUrl: profilePictureUrl,
        licensingCouncils: licensingCouncils,
        carClasses: carClasses,
        lastActiveDate: selectedEditDriver.lastActiveDate,
        phoneNumber:
          phoneNumber.length === 11 ? phoneNumber.slice(1, 11) : phoneNumber,
        carDescriptionShort: carDescriptionShort,
        creationDateTime: getCurrentDateTime(),
        rating: selectedEditDriver.rating,
        badgeNumber: badgeNumber,
        licenceNumber: licenceNumber,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        postCode: postCode,
        city: city,
        dateOfBirth: dateOfBirth.toISOString(),
        expirations: expirations.map((val) => {
          return { ...val, expirationId: val.id };
        }),
      };

      if (stripeAcc) {
        driverObj = { ...driverObj, stripeAccountId: stripeAcc };
      }

      if (selectedEditDriver.stripeVerification) {
        driverObj = {
          ...driverObj,
          stripeVerification: selectedEditDriver.stripeVerification,
        };
      }

      if (exp_list.length > 0) {
        driverObj = { ...driverObj, expirations: exp_list };
      }
      dispatch(
        setDriverList([
          ...driverList.map((val: any) => {
            if (val.id === driverObj.id) {
              return driverObj;
            } else {
              return val;
            }
          }),
        ])
      );
      db.ref("/users/" + selectedEditDriver.id).set(driverObj);
      toast.success("User successfully updated");

      clearStates();
      dispatch(setSelectedEditDriver(undefined));

      if (selectedViewDriver) {
        dispatch(setSelectedViewDriver(driverObj));
      }
    } catch (err) {
      toast.error("Something went wrong there...");
    }
  }

  const [liveInitialised, setLiveInitialised] = useState(false);
  const [stripeAcc, setStripeAcc] = useState<any>(undefined);

  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [dateOfBirthError, setDateOfBirthError] = useState("");

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine1Error, setAddressLine1Error] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine2Error, setAddressLine2Error] = useState("");

  const [postCode, setPostCode] = useState("");
  const [postCodeError, setPostCodeError] = useState("");

  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");

  const [currentEditDriver, setCurrentEditDriver] = useState<
    undefined | DriverModel
  >(undefined);
  useEffect(() => {
    if (
      selectedEditDriver &&
      !liveInitialised &&
      (!currentEditDriver || currentEditDriver?.id !== selectedEditDriver.id)
    ) {
      setLiveInitialised(true);
      setCurrentEditDriver(selectedEditDriver);
    }
  }, [liveInitialised]);

  useEffect(() => {
    if (
      liveInitialised &&
      selectedEditDriver &&
      (!currentEditDriver || currentEditDriver.id !== selectedEditDriver.id)
    ) {
      setLiveInitialised(!liveInitialised);
    }
  }, [selectedEditDriver]);

  async function uploadNewDriver() {
    let profilePictureUrl = "";

    auth
      .createUserWithEmailAndPassword(emailAddress, "234ty734gvhriucbxegkww")
      .then(async (authUser: { user: { uid: any } | null }) => {
        if (authUser && authUser.user !== null) {
          const response = await fetch("https://api.ipify.org/?format=json");
          const data = await response.json();
          let ip = data.ip;

          if (ip) {
            if (imagesChanged) {
              // Upload image...
              const response = await fetch(images[0].data_url);
              const selfieBlob = await response.blob();
              const id = authUser.user.uid;

              // Store the selfie in the firebase store
              profilePictureUrl = await storage
                .ref("/images/" + id)
                .put(selfieBlob)
                .then((snapshot: any) => {
                  return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
                });

              toast.success("Image uploaded...");
            }
            let documentList: DriverDocument[] = [];

            const id = authUser.user.uid;
            for (let i = 0; i < files.length; i++) {
              const fileBlob: Blob = await new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.onload = function () {
                  resolve(xhr.response); // when BlobModule finishes reading, resolve with the blob
                };
                xhr.onerror = function () {
                  reject(new TypeError("Network request failed")); // error occurred, rejecting
                };
                xhr.responseType = "blob"; // use BlobModule's UriHandler
                xhr.open("GET", files[i].path, true); // fetch the blob from uri in async mode
                xhr.send(null); // no initial data
              });
              // Store the selfie in the firebase store
              let downloadUrl = await storage
                .ref("/documents/" + id + files[i].name)
                .put(fileBlob, {
                  contentType: files[i].type,
                })
                .then((snapshot: any) => {
                  return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
                });

              const obj: DriverDocument = {
                name: files[i].name,
                creationDate: getCurrentDateTime(),
                downloadUrl: downloadUrl,
                type: files[i].type,
              };

              documentList.push(obj);
            }

            for (let i = 0; i <= carClasses.length - 1; i++) {
              let carClass = carClasses[i];

              db.ref("/car_class_drivers/" + carClass + "/" + id).set(true);
            }

            let exp_list: DriverExpirationModel[] = [];
            // Upload any expirations
            for (let i = 0; i < expirations.length; i++) {
              // Check if uploaded yet...

              let exp = expirations[i];

              if (exp.id.length === 4) {
                let ref = db.ref("/expirations").push({
                  id: exp.id,
                  driverId: id,
                  uploadDate: exp.uploadDate,
                  expirationDate: exp.expirationDate,
                  expired: exp.expired,
                  title: exp.title,
                });

                if (ref.key !== null) {
                  db.ref(`/expirations/${ref.key}`).set({
                    driverId: id,
                    uploadDate: exp.uploadDate,
                    expirationDate: exp.expirationDate,
                    id: ref.key,
                    expired: exp.expired,
                    title: exp.title,
                  });

                  exp_list.push({ ...exp, id: ref.key, expirationId: ref.key });

                  db.ref(`/driver_expirations/${id}/${ref.key}`).set({
                    id: ref.key,
                    driverId: id,
                    uploadDate: exp.uploadDate,
                    expirationDate: exp.expirationDate,
                    expirationId: ref.key,
                    expired: exp.expired,
                    title: exp.title,
                  });
                }
              } else {
                db.ref(`/expirations/${exp.id}`).set({
                  driverId: id,
                  uploadDate: exp.uploadDate,
                  expirationDate: exp.expirationDate,
                  id: exp.id,
                  title: exp.title,
                  expired: exp.expired,
                });

                db.ref(`/driver_expirations/${id}/${exp.id}`).set({
                  id: exp.id,
                  driverId: id,
                  uploadDate: exp.uploadDate,
                  expirationDate: exp.expirationDate,
                  expirationId: exp.id,
                  title: exp.title,
                  expired: exp.expired,
                });

                exp_list.push({
                  id: exp.id,
                  driverId: id,
                  uploadDate: exp.uploadDate,
                  expirationDate: exp.expirationDate,
                  expirationId: exp.id,
                  title: exp.title,
                  expired: exp.expired,
                });
              }
            }

            let driverObj: DriverModel = {
              firstName: firstName,
              lastName: lastName,
              id: id,
              driver: true,
              emailAddress: emailAddress,
              registrationPlate: registrationPlate,
              documents: documentList,
              profilePictureUrl: profilePictureUrl,
              licensingCouncils: licensingCouncils,
              lastActiveDate: "Never",
              carClasses: carClasses,
              phoneNumber: phoneNumber,
              carDescriptionShort: carDescriptionShort,
              creationDateTime: getCurrentDateTime(),
              rating: 5,
              badgeNumber: badgeNumber,
              licenceNumber: licenceNumber,
              addressLine1: addressLine1,
              addressLine2: addressLine2,
              postCode: postCode,
              city: city,
              dateOfBirth: dateOfBirth.toISOString(),
              expirations: expirations.map((val) => {
                return { ...val, expirationId: val.id };
              }),
            };

            db.ref("/users/" + authUser.user.uid).set(driverObj);

            db.ref("/drivers/" + id).set(id);

            auth.sendPasswordResetEmail(emailAddress);
            dispatch(setDriverList([...driverList, driverObj]));

            toast.success("Driver created...");

            clearStates();
            if (selectedViewDriver) {
              dispatch(setSelectedViewDriver(driverObj));
            }
            dispatch(setCreateNewDriver(false));

            createStripeAccount(
              authUser.user!.uid,
              driverObj,
              ip,
              dateOfBirth,
              addressLine1,
              addressLine2,
              postCode,
              city,
              Number.parseInt(moment(dateOfBirth).format("D")),
              Number.parseInt(moment(dateOfBirth).format("YYYY")),
              Number.parseInt(moment(dateOfBirth).format("M"))
            );
          }
        }
      })
      .catch((error) => {
        toast.error(error.message.split(":")[1].split(".")[0] + ".");
      });
  }

  async function createExpiration() {
    let exp: ExpirationModel = {
      id: (Math.floor(Math.random() * 10000) + 10000).toString().substring(1),
      expirationDate: new Date().toISOString(),
      title: "",
      uploadDate: new Date().toISOString(),
      driverId: selectedEditDriver ? selectedEditDriver.id : "ID",
      expired: false,
    };

    setExpirations([...expirations, exp]);
  }
  return (
    <Fade
      in={
        (createNewDriver && type === DriverModalType.CREATE) ||
        (selectedEditDriver !== undefined && type === DriverModalType.EDIT)
      }
    >
      <Grid
        sx={{
          ...styles.modalBackdrop,
        }}
        container
        justifyContent={"center"}
        alignItems={"center"}
      >
        {carClassListLoading ? (
          <CircularProgress />
        ) : (
          <Grid
            item
            container
            xs={11}
            sm={10}
            alignItems={"flex-start"}
            spacing={isMobile ? 0 : 2}
            sx={{
              ...styles.modal,
            }}
          >
            <Grid
              item
              container
              xs={12}
              sx={{ height: 40 }}
              justifyContent={"space-between"}
            >
              <Typography variant={"h6"}>
                {type === DriverModalType.CREATE
                  ? "Create Driver"
                  : "Edit Driver"}
              </Typography>

              <IconButton
                onClick={() => {
                  dispatch(setSelectedEditDriver(undefined));
                  dispatch(setCreateNewDriver(false));
                  clearStates();
                }}
              >
                <Close />
              </IconButton>
            </Grid>
            <Grid item container xs={12} sm={5}>
              <Grid item container xs={12}>
                <ReactImageUploading
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div
                      className="upload__image-wrapper"
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        display: "flex",
                        backgroundColor: !isDragging
                          ? "rgba(0,0,0,0.04)"
                          : "rgba(95,102,234,0.5)",
                        padding: 10,
                        borderRadius: 5,
                        marginBottom: 5,
                        cursor: "pointer",
                      }}
                      {...dragProps}
                    >
                      {imageList.length === 0 && (
                        <Grid
                          container
                          flexDirection={"column"}
                          alignItems={"center"}
                        >
                          <img
                            src={"/static/avatar.svg"}
                            style={{
                              width: 140,
                              height: 140,

                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              borderRadius: 400,
                              background: "none",
                            }}
                            onClick={onImageUpload}
                          />
                          <Grid
                            container
                            item
                            sx={{ width: "60%", marginTop: 2 }}
                          >
                            <Typography variant="body1" textAlign={"center"}>
                              Click{" "}
                              <span
                                style={{
                                  color: primaryColor,
                                  textDecoration: "underline",
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  onImageUpload();
                                }}
                              >
                                here
                              </span>{" "}
                              to upload an image, or drag it over this area.
                            </Typography>
                          </Grid>
                        </Grid>
                      )}

                      {imageList.map((image, index) => {
                        let src = image["data_url"];
                        return (
                          <div key={index} className="image-item">
                            <div
                              style={{
                                width: 140,
                                height: 140,
                                borderRadius: 400,
                                overflow: "hidden",
                                backgroundImage: `url("${src}")`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            ></div>
                            <IconButton
                              onClick={() => onImageRemove(index)}
                              style={{
                                backgroundColor: "red",
                                padding: 10,
                                borderRadius: 50,
                                width: 40,
                                height: 40,
                                position: "relative",
                                top: -130,
                                right: -110,
                              }}
                            >
                              <Close style={{ color: "white" }} />
                            </IconButton>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </ReactImageUploading>
                {imagesError !== "" && (
                  <Grid
                    style={{
                      width: "100%",
                      marginBottom: 10,
                      marginTop: 5,
                      justifyContent: "center",
                    }}
                  >
                    <Typography style={{ textAlign: "left", color: "red" }}>
                      {imagesError}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                }}
              >
                FIRST NAME:
              </Typography>
              <TextField
                placeholder="First Name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: firstNameError ? 5 : 10,
                }}
              />

              {firstNameError !== "" && (
                <Grid
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 5,
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ textAlign: "left", color: "red" }}>
                    {firstNameError}
                  </Typography>
                </Grid>
              )}

              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                }}
              >
                LAST NAME:
              </Typography>
              <TextField
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: lastNameError ? 5 : 10,
                }}
              />

              {lastNameError !== "" && (
                <Grid
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 5,
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ textAlign: "left", color: "red" }}>
                    {lastNameError}
                  </Typography>
                </Grid>
              )}

              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                }}
              >
                PHONE NUMBER:
              </Typography>
              <TextField
                placeholder="Phone Number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>+44</Typography>
                    </InputAdornment>
                  ),
                }}
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: phoneNumberError ? 5 : 10,
                }}
              />

              {phoneNumberError !== "" && (
                <Grid
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 5,
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ textAlign: "left", color: "red" }}>
                    {phoneNumberError}
                  </Typography>
                </Grid>
              )}
              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                }}
              >
                EMAIL ADDRESS:
              </Typography>
              <TextField
                placeholder="Email Address"
                value={emailAddress}
                onChange={(e) => {
                  setEmailAddress(e.target.value);
                }}
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: emailAddressError ? 5 : 10,
                }}
              />

              {emailAddressError !== "" && (
                <Grid
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 5,
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ textAlign: "left", color: "red" }}>
                    {emailAddressError}
                  </Typography>
                </Grid>
              )}

              {/* Date of birth */}
              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                }}
              >
                DATE OF BIRTH:
              </Typography>

              <Grid item xs={12} paddingTop={1} paddingBottom={1}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={enGB}
                >
                  <DatePicker
                    label="Date of Birth"
                    value={dateOfBirth}
                    onChange={(newValue: any) => setDateOfBirth(newValue)}
                  />
                </LocalizationProvider>
              </Grid>

              {dateOfBirthError !== "" && (
                <Grid
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 5,
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ textAlign: "left", color: "red" }}>
                    {dateOfBirthError}
                  </Typography>
                </Grid>
              )}
              {/* Address line 1 */}
              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                }}
              >
                ADDRESS LINE 1:
              </Typography>
              <TextField
                placeholder="Address Line 1"
                value={addressLine1}
                onChange={(e) => {
                  setAddressLine1(e.target.value);
                }}
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: addressLine1Error ? 5 : 10,
                }}
              />

              {addressLine1Error !== "" && (
                <Grid
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 5,
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ textAlign: "left", color: "red" }}>
                    {addressLine1Error}
                  </Typography>
                </Grid>
              )}
              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                }}
              >
                ADDRESS LINE 2 (Optional):
              </Typography>
              <TextField
                placeholder="Address Line 2"
                value={addressLine2}
                onChange={(e) => {
                  setAddressLine2(e.target.value);
                }}
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: addressLine2Error ? 5 : 10,
                }}
              />

              {addressLine2Error !== "" && (
                <Grid
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 5,
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ textAlign: "left", color: "red" }}>
                    {addressLine2Error}
                  </Typography>
                </Grid>
              )}
              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                }}
              >
                CITY:
              </Typography>
              <TextField
                placeholder="City"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: cityError ? 5 : 10,
                }}
              />

              {cityError !== "" && (
                <Grid
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 5,
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ textAlign: "left", color: "red" }}>
                    {cityError}
                  </Typography>
                </Grid>
              )}
              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                }}
              >
                POST CODE:
              </Typography>
              <TextField
                placeholder="Post Code"
                value={postCode}
                onChange={(e) => {
                  setPostCode(e.target.value);
                }}
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: postCodeError ? 5 : 10,
                }}
              />

              {postCodeError !== "" && (
                <Grid
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 5,
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ textAlign: "left", color: "red" }}>
                    {postCodeError}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={7}
              alignItems={"flex-start"}
              spacing={2}
            >
              <Grid item container xs={12} flexDirection={"column"}>
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  style={{
                    fontSize: 12,
                    color: "rgba(0,0,0,0.7)",
                    marginBottom: 2,
                  }}
                >
                  LICENSING COUNCIL(S)
                </Typography>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={licensingCouncils}
                  onChange={(e) => {
                    let loc: Location[] = e.target.value as Location[];
                    setLicensingCouncils(loc);
                  }}
                  style={{
                    width: "100%",
                    marginBottom: licensingCouncilsError === "" ? 5 : 10,
                  }}
                  input={<OutlinedInput id="select-multiple-chip" />}
                  renderValue={(selected) => {
                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.length &&
                          selected.map((value) => (
                            <Chip
                              key={value.toString()}
                              label={value.toString()}
                            />
                          ))}
                      </Box>
                    );
                  }}
                  MenuProps={MenuProps}
                  label="Multi-Selection"
                >
                  <MenuItem
                    key={"NEWPORT"}
                    value={Location.NEWPORT}
                    style={getStyles("NEWPORT", ["NEWPORT"], theme)}
                  >
                    Newport
                  </MenuItem>

                  <MenuItem
                    key={"CARDIFF"}
                    value={Location.CARDIFF}
                    style={getStyles("CARDIFF", ["CARDIFF"], theme)}
                  >
                    Cardiff
                  </MenuItem>
                </Select>

                {licensingCouncilsError !== "" && (
                  <Grid
                    style={{
                      width: "100%",
                      marginBottom: 10,
                      marginTop: 5,
                      justifyContent: "center",
                    }}
                  >
                    <Typography style={{ textAlign: "left", color: "red" }}>
                      {licensingCouncilsError}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <Grid item container xs={12} sm={6} flexDirection={"column"}>
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  style={{
                    fontSize: 12,
                    color: "rgba(0,0,0,0.7)",
                    marginBottom: 2,
                  }}
                >
                  LICENCE NUMBER
                </Typography>
                <TextField
                  placeholder="Licence Nunber"
                  value={licenceNumber}
                  onChange={(e) => {
                    setLicenceNumber(e.target.value);
                  }}
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    marginBottom: licenceNumberError === "" ? 5 : 10,
                  }}
                />
                {licenceNumberError !== "" && (
                  <Grid
                    style={{
                      width: "100%",
                      marginBottom: 10,
                      marginTop: 5,
                      justifyContent: "center",
                    }}
                  >
                    <Typography style={{ textAlign: "left", color: "red" }}>
                      {licenceNumberError}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item container xs={12} sm={6} flexDirection={"column"}>
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  style={{
                    fontSize: 12,
                    color: "rgba(0,0,0,0.7)",
                    marginBottom: 2,
                  }}
                >
                  BADGE NUMBER
                </Typography>
                <TextField
                  placeholder="Badge Nunber"
                  value={badgeNumber}
                  onChange={(e) => {
                    setBadgeNumber(e.target.value);
                  }}
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    marginBottom: badgeNumberError === "" ? 5 : 10,
                  }}
                />
                {badgeNumberError !== "" && (
                  <Grid
                    style={{
                      width: "100%",
                      marginBottom: 10,
                      marginTop: 5,
                      justifyContent: "center",
                    }}
                  >
                    <Typography style={{ textAlign: "left", color: "red" }}>
                      {badgeNumberError}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <Grid item container xs={12} flexDirection={"column"}>
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  style={{
                    fontSize: 12,
                    color: "rgba(0,0,0,0.7)",
                    marginBottom: 2,
                    marginTop: 2,
                  }}
                >
                  CAR DESC (SHORT):
                </Typography>
                <TextField
                  placeholder="Car Description"
                  value={carDescriptionShort}
                  onChange={(e) => {
                    setCarDescriptionShort(e.target.value);
                  }}
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    marginBottom: carDescriptionShortError ? 5 : 10,
                  }}
                />

                {carDescriptionShortError !== "" && (
                  <Grid
                    style={{
                      width: "100%",
                      marginBottom: 10,
                      marginTop: 5,
                      justifyContent: "center",
                    }}
                  >
                    <Typography style={{ textAlign: "left", color: "red" }}>
                      {carDescriptionShortError}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item container xs={12} flexDirection={"column"}>
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  style={{
                    fontSize: 12,
                    color: "rgba(0,0,0,0.7)",
                    marginBottom: 2,
                    marginTop: 2,
                  }}
                >
                  CAR CLASSES
                </Typography>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={carClasses}
                  onChange={(e) => {
                    if (e.target.value) {
                      setCarClasses(
                        Array.isArray(e.target.value)
                          ? e.target.value
                          : [e.target.value]
                      );
                    }
                  }}
                  style={{
                    width: "100%",
                    marginBottom: carClassesError ? 5 : 10,
                  }}
                  input={<OutlinedInput id="select-multiple-chip" />}
                  renderValue={(selected) => {
                    let list = selected;
                    if (!Array.isArray(selected)) {
                      list = [selected];
                    }
                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {list.length &&
                          list
                            .filter(
                              (val) =>
                                Array.isArray(carClasses) &&
                                carClasses.find(
                                  (val2) => val2.id === val.id
                                ) !== undefined
                            )
                            .map((value) => {
                              return (
                                <Chip
                                  key={value.toString()}
                                  label={
                                    carClassList.find(
                                      (val: any) => val.id === value
                                    )?.name
                                  }
                                />
                              );
                            })}
                      </Box>
                    );
                  }}
                  MenuProps={MenuProps}
                  label="Multi-Selection"
                >
                  {carClassList &&
                    carClassList.length &&
                    carClassList.map((val: CarClassModel) => {
                      return (
                        <MenuItem
                          key={val.id}
                          value={val.id}
                          style={getStyles(val.name, [val.name], theme)}
                        >
                          {val.name}
                        </MenuItem>
                      );
                    })}
                </Select>

                {carClassesError !== "" && (
                  <Grid
                    style={{
                      width: "100%",
                      marginBottom: 10,
                      marginTop: 5,
                      justifyContent: "center",
                    }}
                  >
                    <Typography style={{ textAlign: "left", color: "red" }}>
                      {carClassesError}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item container xs={12} spacing={1}>
                <Grid item container xs={12} sm={6} alignContent={"flex-start"}>
                  <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    style={{
                      fontSize: 12,
                      color: "rgba(0,0,0,0.7)",
                      marginTop: 2,
                      marginBottom: 2,
                    }}
                  >
                    REGISTRATION PLATE:
                  </Typography>
                  <Grid item container xs={12}>
                    <RegPlateTextField
                      placeholder="Enter Reg..."
                      value={registrationPlate}
                      onChange={(e) => {
                        setRegistrationPlate(e.target.value);
                      }}
                      style={{
                        width: "100%",
                        marginTop: 0,
                        borderRadius: 10,
                        height: 60,
                        alignContent: "center",
                        marginBottom: registrationPlateError ? 5 : 10,
                      }}
                    />
                  </Grid>

                  {registrationPlateError !== "" && (
                    <Grid
                      style={{
                        width: "100%",
                        marginBottom: 10,
                        marginTop: 5,
                        justifyContent: "center",
                      }}
                    >
                      <Typography style={{ textAlign: "left", color: "red" }}>
                        {registrationPlateError}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent={"space-between"}
                  sx={{ marginBottom: 1 }}
                >
                  <Grid item>
                    <Typography
                      variant="h6"
                      fontWeight={"bold"}
                      style={{
                        fontSize: 12,
                        color: "rgba(0,0,0,0.7)",
                        marginTop: 2,
                        marginBottom: 2,
                      }}
                    >
                      EXPIRATIONS:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      style={{
                        height: 30,
                        zIndex: 20,
                        width: 30,
                        backgroundColor: primaryColor,
                        borderRadius: 40,
                      }}
                      onClick={() => {
                        createExpiration();
                      }}
                    >
                      <Add style={{ fontSize: 12, color: "white" }} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container justifyContent={"center"}>
                  {expirations.length === 0 ? (
                    <Grid
                      item
                      xs={6}
                      flexDirection={"column"}
                      alignItems={"center"}
                    >
                      <img
                        src={"/static/calendar.svg"}
                        style={{
                          width: 140,
                          height: 140,

                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          background: "none",
                        }}
                      />
                      <Typography>No Expirations Set</Typography>
                    </Grid>
                  ) : (
                    <Grid container>
                      {expirations.map((val: ExpirationModel) => {
                        return (
                          <Grid item container sx={{ marginBottom: 1 }}>
                            <Grid item container spacing={2}>
                              <Grid item xs={6}>
                                <TextField
                                  value={val.title}
                                  placeholder="Title"
                                  style={{ width: "100%" }}
                                  onChange={(e) => {
                                    setExpirations(
                                      expirations.map((val2) => {
                                        if (val2.id === val.id) {
                                          return {
                                            ...val2,
                                            title: e.target.value,
                                          };
                                        } else {
                                          return val2;
                                        }
                                      })
                                    );
                                  }}
                                />
                              </Grid>

                              <Grid item xs={5}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                  adapterLocale={enGB}
                                >
                                  <DatePicker
                                    value={new Date(val.expirationDate)}
                                    onChange={(newValue) => {
                                      if (newValue !== null) {
                                        setExpirations(
                                          expirations.map((val2) => {
                                            if (val2.id === val.id) {
                                              return {
                                                ...val2,
                                                expirationDate: new Date(
                                                  newValue
                                                ).toISOString(),
                                              };
                                            } else {
                                              return val2;
                                            }
                                          })
                                        );
                                      }
                                    }}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={1}>
                                <IconButton
                                  onClick={() => {
                                    setDeletedExpirations([
                                      ...deletedExpirations,
                                      val,
                                    ]);
                                    setExpirations(
                                      expirations.filter((val2) => {
                                        if (val.id !== val2.id) {
                                          return val;
                                        }
                                      })
                                    );
                                  }}
                                >
                                  <Delete color="error" />
                                </IconButton>
                              </Grid>
                            </Grid>

                            {val.error && (
                              <Grid
                                style={{
                                  width: "100%",
                                  marginBottom: 10,
                                  marginTop: 5,
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  style={{ textAlign: "left", color: "red" }}
                                >
                                  {val.error}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} sx={{ marginBottom: 2 }}>
              <Typography
                variant="h6"
                fontWeight={"bold"}
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,0.7)",
                  marginBottom: 2,
                  marginTop: 2,
                }}
              >
                DOCUMENTS:
              </Typography>
              <Grid item container xs={12} alignItems={"flex-start"}>
                {files && files.length !== 0 && (
                  <Grid
                    item
                    container
                    xs={12}
                    spacing={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ height: 30 }}
                  >
                    <Grid item container xs={7}>
                      <Typography
                        variant="h6"
                        fontWeight={"bold"}
                        style={{
                          fontSize: 10,
                          color: "rgba(0,0,0,0.7)",
                        }}
                      >
                        FILE NAME:
                      </Typography>
                    </Grid>
                    <Grid item container xs={4}>
                      <Typography
                        variant="h6"
                        fontWeight={"bold"}
                        style={{
                          fontSize: 10,
                          color: "rgba(0,0,0,0.7)",
                        }}
                      >
                        TYPE:
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {files &&
                  files.map((val: any, index: number) => {
                    return (
                      <Grid
                        container
                        item
                        xs={12}
                        spacing={2}
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{ height: 48 }}
                        key={index}
                      >
                        <Grid
                          item
                          container
                          xs={7}
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Typography variant={"h6"} fontSize={13}>
                            {val.name}{" "}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={3}
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Typography variant={"body1"} fontSize={13}>
                            {val.type}
                          </Typography>
                        </Grid>
                        <Grid item container xs={1}>
                          <IconButton
                            style={{ zIndex: 10 }}
                            onClick={() => {
                              window.open(val.downloadUrl, "_blank")?.focus();
                            }}
                          >
                            <Launch />
                          </IconButton>
                        </Grid>
                        <Grid item container xs={1}>
                          <IconButton
                            style={{ zIndex: 10 }}
                            onClick={() => {
                              setDeletedFiles([...deletedFiles, val]);
                              setFiles(
                                files.filter(
                                  (val2: any) =>
                                    val.downloadUrl !== val2.downloadUrl
                                )
                              );
                              setFiles(
                                files.filter(
                                  (val2) => val2.downloadUrl !== val.downloadUrl
                                )
                              );
                            }}
                          >
                            <Delete style={{ color: "red" }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}

                <PaperDropzone
                  files={files}
                  setFiles={(val: any) => {
                    setFiles(val);
                    setFiles(val);
                  }}
                  dropzoneRef={dropzoneRef}
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              justifyContent={"flex-end"}
              spacing={2}
              sx={{
                position: "sticky",
                bottom: -20,
                backgroundColor: "white",
                paddingBottom: 2,
              }}
            >
              <Button
                variant={"contained"}
                startIcon={<Cancel />}
                style={{ backgroundColor: "red", marginRight: 5 }}
                onClick={() => {
                  clearStates();
                  dispatch(setSelectedEditDriver(undefined));
                  setLiveInitialised(false);
                  dispatch(setCreateNewDriver(false));
                }}
              >
                Cancel
              </Button>
              <Button
                variant={"contained"}
                startIcon={<Save />}
                onClick={() => {
                  validateDriver();
                }}
                style={{ backgroundColor: primaryColor }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Fade>
  );
}
